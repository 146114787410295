const newStream = (stream) => {
    return new Promise(async (resolve, reject) => {
        const streamManipBasicURL = "https://simulator.vanilla.nseven.xyz"
        const streamManipEndpoint = "/api/sp/stream";
        const streamManipUrl = streamManipBasicURL + streamManipEndpoint;
        const streamManipResult = await fetch(streamManipUrl, {
            method: 'POST',
            headers: {
                'Content-Type': "application/json",
            },
            body: JSON.stringify(stream)
        })
        if(!streamManipResult.ok){
            return reject(new Error(`HTTP Error Status: ${res.status}`))
        }
        resolve(null);
    })
}

export default newStream;