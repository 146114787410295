import { CognitoUserPool } from "amazon-cognito-identity-js";
import UserAuth from "./authentication/UserAuth";
import StreamManip from "./streamManip/StreamManip";

class N7SDK {
    constructor(){
        const spUserPoolData = {
            UserPoolId: "us-east-1_MOfKbEKYK",
            ClientId: "7v59jac1e9819a0qsjh4gb1n0n"
        }
        const spUserPool = new CognitoUserPool(spUserPoolData);

        this.SpUserAuth = new UserAuth(spUserPool);
        this.SpStreamManip = new StreamManip();
    }
}

export default N7SDK;