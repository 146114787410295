import userRegistration from "./userRegistration";
import userConfirmation from "./userConfirmation";
import checkSession from "./checkSession";
import userLogin from "./userLogin";
import userLogout from "./userLogout";

class UserAuth {
    constructor(userPool){
        this.userPool = userPool;
    }

    registerUser(username, password, email){
        return userRegistration(this.userPool, username, password, email);
    }

    confirmeUser(username, verificationCode){
        return userConfirmation(this.userPool, username, verificationCode);
    }

    login(username, password, callback){
        return userLogin(this.userPool, username, password, callback);
    } 

    userSession(){
        return checkSession();
    }

    logout(){
        return userLogout(this.userPool);
    }
}

export default UserAuth;