import newStream from "./newStream";

class StreamManip {
    constructor(){
    }

    createNewStream(){
        return newStream(stream);
    }
}

export default StreamManip;